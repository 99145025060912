/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Typist from 'react-typist';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { StyledButton } from 'components/core';
import { Container, Layout } from 'layouts';

const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 2rem 10vw;
  h1 {
    margin-bottom: 50px;
  }
  p {
    line-height: 0;
    font-family: ${props => props.theme.fontFamily.code}
  }
  #terminal-404 {
    white-space: pre;
    background-color: ${props => props.theme.colors.black.terminal};
    color: ${props => props.theme.colors.white.terminal};
    text-align: left;
    padding: 20px;
    width: 100%;
    min-height: 500px;
    font: 300 1rem/1.20rem "Ubuntu Mono", Inconsolata, courier, monospace;
    border-radius: 0 0 4px 4px;
    overflow: auto;
  }
  /* Browser mockup code
  * Contribute:https://gist.github.com/jarthod/8719db9fef8deb937f4f
  * Live example:https://codepen.io/adrienjarthon/pen/ogjjoj
  */
  .browser-mockup {
    margin-bottom: 50px;
    border-top: 50px solid #f7f7f7;
    position: relative;
    border-radius: 4px;
    -webkit-box-shadow: 5.665px 9.429px 35px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 5.665px 9.429px 35px 0px rgba(0, 0, 0, 0.2);
  }
  .browser-mockup:before {
    display: block;
    position: absolute;
    content: '';
    top: -29px;
    left: 30px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #f44;
    -webkit-box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
    box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
  }
  .browser-mockup.with-tab:after {
    display: block;
    position: absolute;
    content: '';
    top: -50px;
    left: 115px;
    width: 20%;
    height: 0rem;
    border-bottom: 50px solid white;
    border-left: 1.5em solid transparent;
    border-right: 1.5em solid transparent;
  }
  .browser-mockup.with-url:after {
    display: block;
    position: absolute;
    content: '';
    top: -1.6rem;
    left: 5.5rem;
    width: calc(100% - 6em);
    height: 1.2rem;
    border-radius: 2px;
    background-color: white;
  }
  .browser-mockup>* {
    display: block;
  }
  .browser-mockup img.img-fluid {
    border-radius: 0 0 4px 4px;
  }
  .Typist .Cursor {
    display: inline-block;
  }
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    #terminal {
      font-size:.72rem;
      line-height:1.15rem;
    }
  }
`;

const cursorOptions = {
  show: true,
  blink: true,
  element: '|',
  hideWhenDone: true,
  hideWhenDoneDelay: 1000
};

const ErrorPage = center => (
  <Layout>
    <Helmet title={'Page not found | Stackery'} />
    <Container center={center}>
      <Wrapper>
        <h1>Oh no, a 404!</h1>
        <div className='browser-mockup'>
          <div id='nav-tabContent' className='tab-content'>
            <div id='terminal-404' className='terminal'>
              <Typist stdTypingDelay={15} cursor={cursorOptions}>
                <Typist.Delay ms={1000} />
                <p># The page you were looking for was not found</p>
                <Typist.Delay ms={2000} />
                <p># You can click the button below to be taken to our homepage</p>
                <Typist.Delay ms={800} />
                <p># Or just hang out here for a bit, enjoying the quiet...</p>
                <Typist.Delay ms={1000} />
                <p># </p>
                <Typist.Delay ms={500} />
                <p># </p>
                <Typist.Delay ms={1000} />
                <p># Isn't this just what you needed?</p>
                <Typist.Delay ms={1000} />
                <p># You work hard, you deserve a break.</p>
                <Typist.Delay ms={2000} />
                <p># Maybe get yourself a nice cup of coffee?</p>
                <Typist.Delay ms={1000} />
                <p># We'll wait</p>
                <Typist.Delay ms={500} />
                <p># </p>
                <Typist.Delay ms={500} />
                <p># </p>
                <Typist.Delay ms={500} />
                <p># </p>
                <Typist.Delay ms={2000} />
                <p># See, wasn't that nice?</p>
                <Typist.Delay ms={500} />
                <p># </p>
                <Typist.Delay ms={1000} />
                <p># If you want to see what other nice things Stackery can do for you, click the button below to go to our homepage!</p>
                <Typist.Delay ms={1000} />
              </Typist>
            </div>
          </div>
        </div>
        <Link to='/'><StyledButton color='primary' size='large' variant='contained' type='text' text='Take me home' noShadow /></Link>
      </Wrapper>
    </Container>
  </Layout>
);

export default ErrorPage;

ErrorPage.propTypes = {
  center: PropTypes.object
};
